export const ResumeData = [
    {
        company: "Hayward",
        url: "https://hayward.com/",
        position: "Front-End Web Developer & Designer",
        year: "2018 - 2022",
        content: "",
        bulletPoints: [
            'Design landing pages, banners & single page applications for marketing campaigns and sales operations',
            'Develop interactive/dynamic web components and UI handling store products, services and media using JS & JS frameworks/libraries',
            'Draft mockups/interactive prototypes and present to VPs, Directors and XFN stakeholders',
            'Collaborate with backend engineers, product managers and sales team to transform creative concepts to production realities',
            'Overhauled online store and accounts portal UI/UX to improve customer shopping experience'
        ],
        skillsArr: [
            'HTML', 'SASS', 'Javascript', 'React', 'REST API', 'jQuery', 'Adobe XD', 'Photoshop'
        ]
    }
    ,
    {
        company: "Freelance",
        url: "#",
        position: "Web Consultant",
        year: "2018 - Present",
        content: "",
        bulletPoints: [
            'Provide consultations on creating, launching and maintaining ecommerce sites for new business owners',
            'Register new domains and set up new sites including home page, product pages etc.',
            'Create branding guidelines, site themes, graphics, and product photos',
            'Implement effective SEO practices resulting in higher results on Google'
        ],
        skillsArr: [
            'Shopify', 'WordPress', 'Google Analytics', 'HTML', 'Javascript', 'React', 'REST API', 'NodeJS'
        ]
    },
    {
        company: "Turner Syndrome Fdn.",
        url: "https://turnersyndromefoundation.org/",
        position: "Lead Web Developer",
        year: "2017 - 2018",
        content: "",
        bulletPoints: [
            'Designed and developed an entirely new site to replace outdated company site with WordPress Business Platform',
            'Provided consultations and web solutions such as CMS, hosting and UX/UI design to company directors',
            'Researched and implemented modern & best practices for SEO, Web Accessibility and User Experience',
            'Provide leadership with marketing & operations team through close collaboration, knowledge shares, and mentorship'
        ],
        skillsArr: [
            'HTML', 'Javascript', 'CSS', 'PHP', 'WordPress', 'CSS'
        ]
    }
]